@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
  width: 100%;
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 显示三行 */
  line-clamp: 2; /* 显示三行 */
}
.account-checkbox-group {
  flex-wrap: wrap;
  justify-content: space-between;
}
.account-checkbox-group .ant-checkbox-wrapper {
  width: 30%;
  display: flex;
  align-items: center;
}
